import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Dialog, DialogContent, Typography, Grid } from "@mui/material";

import { SubheaderSection } from "../../components";
import SuccessLogo from ".././../layouts/application layout components/assets/success.svg";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    // boxShadow: "rgb(31, 200, 127, 0.6) 0px 6px 10px 1px",
    background: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
}));

const ContentBox = styled(Box)({
  padding: "30px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
});

const EventBookingSuccess = ({
  open,
  setOpen,
  SuccessMessage = "Process Completed Successfully",
  handleOnClick,
  buttonContent = "Next Step",
  CloseDialog,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={CloseDialog && (() => setOpen(false))}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: "0px 24px" }}>
        <ContentBox>
          <img src={SuccessLogo} style={{ width: "95px" }} />
          <SubheaderSection
            textAlign="center"
            title="yay!!"
            titleColor="#1FC87F"
          />
          <Typography
            variant="h4"
            fontWeight={700}
            style={{
              fontSize: {
                xs: "1.25rem",
                sm: "1.25rem",
                md: "1.4rem",
              },
            }}
          >
            {SuccessMessage}
          </Typography>

          {Boolean(buttonContent !== "Next Step") && (
            <Grid item sx={{ marginTop: "25px" }}>
              <Typography
                variant="span"
                sx={{
                  fontSize: { xs: "12px", sm: "20px" },
                  color: "#1FC87F",
                  marginTop: "10px",
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "900 !important",
                  background:
                    "conic-gradient(from 90deg at 3px 3px, rgba(0, 0, 0, 0) 90deg, rgb(31, 200, 127) 0deg) 0.45rem 0.45rem / calc(100% - 3px - 0.9rem) calc(100% - 3px - 0.9rem)",
                  padding: "15px",
                }}
                onClick={handleOnClick}
              >
                {buttonContent.toUpperCase()}{" "}
              </Typography>
            </Grid>
          )}
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default EventBookingSuccess;
