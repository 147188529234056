import React from "react";
import { SubheaderSection, LoadingButton } from "../../components";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import BTError from ".././.././layouts/application layout components/assets/BTError.png";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    // boxShadow: "rgb(240, 94, 7, 0.75) 0px 5px 6px 1px",
    height: "auto",
    background: "rgb(255, 255, 255)",
    margin: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
}));

const StyledImage = styled("img")(({ theme }) => ({
  maxWidth: "96px",
  height: "auto",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "72px",
  },
}));

const Instruction = ({ open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <DialogContent>
        <ContentBox>
          <Grid item container justifyContent={"end"} xs={12}>
            <CloseIcon
              onClick={() => setOpen(false)}
              style={{ fontSize: "35px", color: "rgb(219 141 0)" }}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <img src={BTError} style={{ width: "255px" }} />

            <Typography
              variant="h5"
              fontWeight={900}
              textAlign={"center"}
              sx={{
                fontSize: {
                  xs: "1.65rem",
                  sm: "1.95rem",
                  md: "2rem",
                },
                color: "rgb(219 141 0)",
                "-webkit-text-stroke-width": ".25px",
                fontWeight: "bold",
                fontFamily: "Poppins !important",
              }}
            >
              Disclaimer
            </Typography>
          </Grid>

          <Typography
            variant="h6"
            fontWeight={500}
            pt={5}
            pb={5}
            textAlign={"start"}
            sx={{
              fontSize: {
                xs: "0.95rem",
                sm: "1.1rem",
              },
              color: "rgb(219 141 0 / 60%)",
              padding: "20px 3%",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            We do not store your face images. they are used only for
            verification and are immediately discarded once the process is
            complete, but they are temporarily stored on your device, and your
            image is not saved or used for any other purpose beyond this
            verification session.
          </Typography>
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default Instruction;
