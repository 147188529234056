import React from "react";
import { Typography, Tooltip, Box } from "@mui/material";
import refresh from "./../../src/pages/home components/assets/refresh.png";

const CaptchaDisplay = ({ captcha, generateCaptcha }) => (
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
      my: 2,
    }}
  >
    <Typography
      variant="body2"
      sx={{
        color: "rgb(161, 130, 74)",
        marginRight: "16px",
        fontWeight: 700,
      }}
    >
      Type the captcha above:
    </Typography>
    <Typography
      variant="h5"
      component="strong"
      sx={{
        color: "rgb(161, 130, 74)",
        padding: "5px",
        userSelect: "none",
      }}
    >
      {captcha}
    </Typography>
    <Tooltip title="Refresh Captcha">
      <img
        src={refresh}
        alt="Refresh Captcha"
        onClick={generateCaptcha}
        style={{
          cursor: "pointer",
          paddingLeft: "5px",
          width: "35px",
          height: "auto",
        }}
      />
    </Tooltip>
  </Box>
);

export default CaptchaDisplay;
