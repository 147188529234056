import { Formik } from "formik";
import SyncIcon from "@mui/icons-material/Sync";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Fragment, useEffect, useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Button,
  Grid,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import ImgCapture from "./ImgCapture";
import { useAxios } from "../../hooks";
import { Label, OuterBox, TextField } from "../../components";
import { setDisplayName } from "../../redux/slices/memberSlice";

const MainGateEntryWithFrontCamera = () => {
  const axios = useAxios({
    baseURL: "mox_api_2",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authToken, displayName, eventID } = useSelector(
    (state) => state.memberDetails
  );

  const videoRef = useRef(null);

  const [userDetail, setUserDetail] = useState({});
  const [gates, setGates] = useState([]);
  const [userStep, setUserStep] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedGate, setSelectedGate] = useState(
    Number(sessionStorage.getItem("gateMateNo")) || 0
  );
  const [entryStatus, setEntryStatus] = useState(false);
  const [agentName, setAgentName] = useState(displayName || "");
  const [cameraScreen, setCameraScreen] = useState("environment");
  const [guardDetails, setGuardDetails] = useState({
    name: displayName || "",
    gateNo: 0,
    QRCode: "",
    ImgData: "",
  });
  const [faceVerificationStatus, setFaceVerificationStatus] =
    useState(undefined);
  const [mainEntryStatus, setMainEntryStatus] = useState(undefined);
  const [appointmentStatus, setAppointmentStatus] = useState(undefined);
  const [bookingExistsStatus, setBookingExistsStatus] = useState(undefined);

  useEffect(() => {
    if (Boolean(displayName)) {
      setUserStep("QRcode");
    }

    axios({ url: "/visitor/dome-list", disableRedirect: true }).then(
      (response) => {
        if (response.status) {
          setGates(response.data);
        }
      }
    );
  }, []);

  useEffect(() => {
    return () => {
      const stopCamera = () => {
        if (videoRef.current && videoRef.current?.srcObject) {
          const tracks = videoRef.current.srcObject?.getTracks();
          tracks.forEach((track) => track.stop());
          videoRef.current.srcObject = null;
        }
      };

      stopCamera();
    };
  }, [location]);

  const StatusMessage = ({
    icon: Icon,
    iconColor,
    text,
    buttonText,
    onClick,
  }) => (
    <Grid
      container
      display="flex"
      sx={{ flexDirection: "column" }}
      alignItems="center"
      justifyContent="center"
    >
      <Icon sx={{ fontSize: "80px", color: iconColor, mb: "30px" }} />
      <audio
        autoPlay
        src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
      ></audio>
      <Typography
        variant="h1"
        component="h2"
        sx={{
          fontWeight: 700,
          fontSize: "18px",
          textAlign: "center",
          color: iconColor,
        }}
      >
        {text}
      </Typography>
      <Button
        variant="outlined"
        sx={{ mt: "30px", color: iconColor, border: `1px solid ${iconColor}` }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Grid>
  );

  const resetStatuses = () => {
    setFaceVerificationStatus(undefined);
    setAppointmentStatus(undefined);
    setMainEntryStatus(undefined);
    setBookingExistsStatus(undefined);
  };

  return (
    <OuterBox>
      {!entryStatus &&
        faceVerificationStatus === undefined &&
        appointmentStatus === undefined && (
          <Formik
            initialValues={guardDetails}
            onSubmit={(formData, { setSubmitting }) => {
              setSubmitting(true);

              let url =
                selectedGate === 1
                  ? "/booking/verify-visitor"
                  : "booking/verify/dome-visitor";

              axios({
                url: url,
                method: "POST",
                data: {
                  machine_id: selectedGate,
                  // ...(selectedGate === 1 && { id: formData.QRCode }),
                  face_data: formData.ImgData,
                  is_manual: false,
                  agent_name: agentName,
                  name: "",
                  booking_id: "",
                  event_id: eventID || sessionStorage.getItem("eventID"),
                },
                customAuthToken: authToken,
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  setEntryStatus(
                    response.data?.appointment_status &&
                      response.data?.face_verification
                  );
                  setUserDetail(response.data?.appointment_data);
                } else {
                  setAppointmentStatus(response.data?.appointment_status);
                  setBookingExistsStatus(response.data?.booking_exist);
                  setFaceVerificationStatus(response.data?.face_verification);
                  setMainEntryStatus(response.data?.main_entry);
                }
                setSubmitting(false);
              });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  <Fragment>
                    {agentName && userStep !== "" && (
                      <Grid container justifyContent={"space-between"}>
                        <Grid
                          item
                          container
                          xs={4}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography sx={{ fontWeight: 700 }}>
                            Switch Camera
                          </Typography>

                          <Button
                            onClick={() => {
                              const stopCamera = () => {
                                if (
                                  videoRef.current &&
                                  videoRef.current?.srcObject
                                ) {
                                  const tracks =
                                    videoRef.current.srcObject?.getTracks();
                                  tracks.forEach((track) => track.stop());
                                  videoRef.current.srcObject = null;
                                }
                              };

                              stopCamera();

                              navigate("/main-gate-entry-back");
                            }}
                          >
                            <SyncIcon />
                          </Button>
                        </Grid>

                        <Grid
                          item
                          container
                          xs={2}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography alignContent={"center"}>
                            Welcome
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {agentName}
                            </span>{" "}
                          </Typography>

                          <Tooltip title="Edit Name">
                            <EditRoundedIcon
                              sx={{
                                fontSize: "30px",
                                borderRadius: "15px",
                                padding: "5px",
                                "&:hover": {
                                  background: "rgb(108 108 108 / 87%)",
                                },
                              }}
                              onClick={() => {
                                const stopCamera = () => {
                                  if (
                                    videoRef.current &&
                                    videoRef.current?.srcObject
                                  ) {
                                    const tracks =
                                      videoRef.current.srcObject?.getTracks();
                                    tracks.forEach((track) => track.stop());
                                    videoRef.current.srcObject = null;
                                  }
                                };

                                stopCamera();
                                setUserStep("");
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}

                    {userStep === "" && (
                      <Grid container>
                        <Grid item xs={4} alignSelf={"center"}>
                          <Label
                            formikProps={formikProps}
                            title={"Enter Your Name"}
                          />
                        </Grid>

                        <Grid item xs={7}>
                          <TextField name="name" formikProps={formikProps} />
                        </Grid>

                        <Grid item xs={1}>
                          <Button
                            variant="contained"
                            sx={{ height: "100%" }}
                            disabled={displayName ? false : !formikProps.dirty}
                            onClick={() => {
                              setAgentName(formikProps.values.name);
                              dispatch(setDisplayName(formikProps.values.name));
                              setUserStep("QRcode");
                            }}
                          >
                            <ArrowForwardIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    )}

                    {userStep === "QRcode" && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h5" textAlign={"center"}>
                            First Select Gate No
                          </Typography>
                        </Grid>

                        <Grid item container m={1} justifyContent={"center"}>
                          <Grid item xs={4}>
                            <TextField
                              select
                              name="gateNo"
                              formikProps={formikProps}
                              onChange={(e) => {
                                sessionStorage.setItem(
                                  "gateMateNo",
                                  e.target.value
                                );

                                setSelectedGate(e.target.value);

                                formikProps.setValues((prev) => {
                                  return { ...prev, gateNo: e.target.value };
                                });
                              }}
                              value={selectedGate}
                            >
                              <MenuItem value={0}>
                                Select Your Current Gate
                              </MenuItem>
                              {gates.map((item) => {
                                return (
                                  <MenuItem value={item._id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                        </Grid>

                        {Boolean(selectedGate !== 0) && (
                          <ImgCapture
                            loading={loading}
                            videoRef={videoRef}
                            bioFormikProps={formikProps}
                            cameraScreen={cameraScreen}
                            onSubmit={formikProps.handleSubmit}
                          />
                        )}
                      </Grid>
                    )}
                  </Fragment>
                  {agentName && userStep !== "" && (
                    <Grid container justifyContent={"space-between"}>
                      <Grid item container xs={4} sx={{ alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 700 }}>
                          Switch Camera
                        </Typography>

                        <Button
                          onClick={() => {
                            const stopCamera = () => {
                              if (
                                videoRef.current &&
                                videoRef.current?.srcObject
                              ) {
                                const tracks =
                                  videoRef.current.srcObject?.getTracks();
                                tracks.forEach((track) => track.stop());
                                videoRef.current.srcObject = null;
                              }
                            };

                            stopCamera();

                            navigate("/main-gate-entry-back");
                          }}
                        >
                          <SyncIcon />
                        </Button>
                      </Grid>

                      <Grid item container xs={2} sx={{ alignItems: "center" }}>
                        <Typography alignContent={"center"}>
                          Welcome
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {agentName}
                          </span>{" "}
                        </Typography>

                        <Tooltip title="Edit Name">
                          <EditRoundedIcon
                            sx={{
                              fontSize: "30px",
                              borderRadius: "15px",
                              padding: "5px",
                              "&:hover": {
                                background: "rgb(108 108 108 / 87%)",
                              },
                            }}
                            onClick={() => {
                              const stopCamera = () => {
                                if (
                                  videoRef.current &&
                                  videoRef.current?.srcObject
                                ) {
                                  const tracks =
                                    videoRef.current.srcObject?.getTracks();
                                  tracks.forEach((track) => track.stop());
                                  videoRef.current.srcObject = null;
                                }
                              };

                              stopCamera();
                              setUserStep("");
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}

                  {userStep === "" && (
                    <Grid container>
                      <Grid item xs={4} alignSelf={"center"}>
                        <Label
                          formikProps={formikProps}
                          title={"Enter Your Name"}
                        />
                      </Grid>

                      <Grid item xs={7}>
                        <TextField name="name" formikProps={formikProps} />
                      </Grid>

                      <Grid item xs={1}>
                        <Button
                          variant="contained"
                          sx={{ height: "100%" }}
                          disabled={displayName ? false : !formikProps.dirty}
                          onClick={() => {
                            setAgentName(formikProps.values.name);
                            dispatch(setDisplayName(formikProps.values.name));
                            setUserStep("QRcode");
                          }}
                        >
                          <ArrowForwardIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {userStep === "QRcode" && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5" textAlign={"center"}>
                          First Select Gate No
                        </Typography>
                      </Grid>

                      <Grid item container m={1} justifyContent={"center"}>
                        <Grid item xs={4}>
                          <TextField
                            select
                            name="gateNo"
                            formikProps={formikProps}
                            onChange={(e) => {
                              sessionStorage.setItem(
                                "gateMateNo",
                                e.target.value
                              );

                              setSelectedGate(e.target.value);

                              formikProps.setValues((prev) => {
                                return { ...prev, gateNo: e.target.value };
                              });
                            }}
                            value={selectedGate}
                          >
                            <MenuItem value={0}>
                              Select Your Current Gate
                            </MenuItem>
                            {gates.map((item) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                      </Grid>

                      {Boolean(selectedGate !== 0) && (
                        <ImgCapture
                          loading={loading}
                          videoRef={videoRef}
                          bioFormikProps={formikProps}
                          cameraScreen={cameraScreen}
                          onSubmit={formikProps.handleSubmit}
                        />
                      )}
                    </Grid>
                  )}
                </Fragment>
              );
            }}
          </Formik>
        )}

      {entryStatus && (
        <Grid
          container
          display={"flex"}
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <audio
            autoPlay
            src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227404.694377_aws.wav"
          >
            {" "}
          </audio>

          <CheckCircleOutlineRoundedIcon
            sx={{
              fontSize: "80px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
              marginBottom: "30px",
            }}
          />
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "35px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
            }}
          >
            Access Granted
          </Typography>
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(108 108 108)",
              mt: "15px",
            }}
          >
            {`Welcome ${userDetail?.first_name ? userDetail?.first_name : ""}${
              userDetail?.last_name ? userDetail?.last_name : ""
            }!`}
          </Typography>
          <Button
            variant="outlined"
            sx={{ marginTop: "30px" }}
            onClick={() => {
              setEntryStatus(false);
              setFaceVerificationStatus(undefined);
              setAppointmentStatus(undefined);
            }}
          >
            Take Next Entry
          </Button>
        </Grid>
      )}

      {bookingExistsStatus !== undefined && bookingExistsStatus === false && (
        <StatusMessage
          icon={CancelRoundedIcon}
          iconColor="red"
          text="Booking not found"
          buttonText="Try Again"
          onClick={resetStatuses}
        />
      )}

      {appointmentStatus !== undefined &&
        appointmentStatus === false &&
        bookingExistsStatus === true && (
          <StatusMessage
            icon={CancelRoundedIcon}
            iconColor="red"
            text="Booking not found"
            buttonText="Try Again"
            onClick={resetStatuses}
          />
        )}

      {faceVerificationStatus !== undefined &&
        faceVerificationStatus === false &&
        bookingExistsStatus === true &&
        appointmentStatus !== false && (
          <StatusMessage
            icon={CancelRoundedIcon}
            iconColor="red"
            text="Face Didn't Match"
            buttonText="Try Again"
            onClick={resetStatuses}
          />
        )}

      {mainEntryStatus !== undefined &&
        mainEntryStatus === false &&
        bookingExistsStatus === true &&
        appointmentStatus === true &&
        faceVerificationStatus !== false && (
          <StatusMessage
            icon={CancelRoundedIcon}
            iconColor="red"
            text="Please verify at the main gate first"
            buttonText="Try Again"
            onClick={resetStatuses}
          />
        )}
    </OuterBox>
  );
};

export default MainGateEntryWithFrontCamera;
