let data = [
  {
    category: "Shops",
    filter:
      "Juwelier Capri Bruno S&#246;hnle,Casio,Coeur de Lion,Daniel Wellington,Emporio Armani,Fossil,Hugo Boss,Joop!,Michael Kors,Mido,Skagen,Thomas Sabo,Tissot,Schmuck,Uhren,Batteriewechsel,Gravur,Juwelier,Kette,Armband,Ohrringe",
    status: "open",
    security: "False",
    name: "Juwelier Capri",
    hour: "06:00 - 22:00",
    hours: {
      Monday: "10:00 - 19:00",
      Tuesday: "10:00 - 19:00",
      Wednesday: "Closed",
      Thursday: "10:00 - 19:00",
      Friday: "10:00 - 19:00",
      Saturday: "10:00 - 19:00",
      Sunday: "10:00 - 19:00",
    },
    location: {
      place: "Terminal 1, Departures",
      secure_area: "In the public sector",
    },
    contact: "+49 711 99776606",
  },
  {
    category: "Service",
    filter:
      "Kiddieland T3/E2 Kinder,spielen,Spielplatz,Langeweile,Spa&#223;,Kiddieland",
    status: "open",
    hour: "12:00 - 16:00",
    security: "False",
    name: "Kiddieland T3/E2",
    hours: {
      Monday: "08:00 - 20:00",
      Tuesday: "08:00 - 20:00",
      Wednesday: "Closed",
      Thursday: "08:00 - 20:00",
      Friday: "08:00 - 20:00",
      Saturday: "08:00 - 20:00",
      Sunday: "08:00 - 20:00",
    },
    location: {
      place: "Terminal 3, Arrivals",
      secure_area: "In the public sector",
    },
  },
  {
    category: "Food",
    filter: "La Trattoria Grab &amp; Fly",
    status: "open",
    hour: "09:00 - 18:00",
    security: "True",
    name: "La Trattoria Grab & Fly",
    hours: {
      Monday: "06:00 - 20:00",
      Tuesday: "06:00 - 20:00",
      Wednesday: "06:00 - 20:00",
      Thursday: "06:00 - 20:00",
      Friday: "06:00 - 20:00",
      Saturday: "06:00 - 20:00",
      Sunday: "06:00 - 20:00",
    },
    location: {
      place: "Terminal 3, Departures",
      secure_area: "Inside security area",
    },
    contact: "+49 711 948-5284",
  },
  {
    category: "Food",
    filter: "Cafe Amore",
    status: "open",
    security: "True",
    name: "Cafe Amore",
    hour: "01:00 - 12:00",
    hours: {
      Monday: "07:00 - 23:00",
      Tuesday: "07:00 - 23:00",
      Wednesday: "Closed",
      Thursday: "07:00 - 23:00",
      Friday: "07:00 - 23:00",
      Saturday: "07:00 - 23:00",
      Sunday: "07:00 - 23:00",
    },
    location: {
      place: "Terminal 2, Departures",
      secure_area: "Inside security area",
    },
    contact: "+49 711 948-1234",
  },
  {
    category: "Shops",
    filter: "Gadget Store Electronics,Phones,Accessories",
    status: "open",
    security: "False",
    name: "Gadget Store",
    hours: {
      Monday: "10:00 - 19:00",
      Tuesday: "10:00 - 19:00",
      Wednesday: "Closed",
      Thursday: "10:00 - 19:00",
      Friday: "10:00 - 19:00",
      Saturday: "10:00 - 19:00",
      Sunday: "10:00 - 19:00",
    },
    hour: "10:00 - 13:00",
    location: {
      place: "Terminal 1, Arrivals",
      secure_area: "In the public sector",
    },
    contact: "+49 711 99775555",
  },
  {
    category: "Service",
    filter: "Relax Spa Massage,Wellness,Spa",
    status: "open",
    security: "True",
    name: "Relax Spa",
    hours: {
      Monday: "08:00 - 22:00",
      Tuesday: "08:00 - 22:00",
      Wednesday: "Closed",
      Thursday: "08:00 - 22:00",
      Friday: "08:00 - 22:00",
      Saturday: "08:00 - 22:00",
      Sunday: "08:00 - 22:00",
    },
    location: {
      place: "Terminal 2, Departures",
      secure_area: "Inside security area",
    },
    contact: "+49 711 948-5678",
  },
  {
    category: "Food",
    filter: "Burger Hub Fast food,Grill,Burgers",
    status: "open",
    security: "False",
    name: "Burger Hub",
    hours: {
      Monday: "11:00 - 22:00",
      Tuesday: "11:00 - 22:00",
      Wednesday: "Closed",
      Thursday: "11:00 - 22:00",
      Friday: "11:00 - 22:00",
      Saturday: "11:00 - 22:00",
      Sunday: "11:00 - 22:00",
    },
    location: {
      place: "Terminal 1, Arrivals",
      secure_area: "In the public sector",
    },
    contact: "+49 711 947-2222",
  },
  {
    category: "Shops",
    filter: "Fashion X Clothes,Accessories,Jewelry",
    status: "closed",
    security: "False",
    name: "Fashion X",
    hours: "Closed for renovation",
    location: {
      place: "Terminal 4, Departures",
      secure_area: "In the public sector",
    },
    contact: "+49 711 954-9876",
  },
  {
    category: "Service",
    filter: "Pet Lounge Animals,Pets,Services",
    status: "open",
    security: "False",
    name: "Pet Lounge",
    hours: {
      Monday: "06:00 - 22:00",
      Tuesday: "06:00 - 22:00",
      Wednesday: "Closed",
      Thursday: "06:00 - 22:00",
      Friday: "06:00 - 22:00",
      Saturday: "06:00 - 22:00",
      Sunday: "06:00 - 22:00",
    },
    location: {
      place: "Terminal 3, Arrivals",
      secure_area: "In the public sector",
    },
    contact: "+49 711 961-3321",
  },
  {
    category: "Food",
    filter: "Pasta Villa Italian,Food,Restaurant",
    status: "closed",
    security: "True",
    name: "Pasta Villa",
    hours: "Temporarily closed",
    location: {
      place: "Terminal 2, Departures",
      secure_area: "Inside security area",
    },
    contact: "+49 711 948-8821",
  },
];

export default data;
