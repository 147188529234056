import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, useTheme } from "@mui/material";
import {
  OuterBox,
  SubheaderSection,
  LoadingButton,
} from "./../../../components";

import pages from "../../../constants/pages";
import { useNavigate, useLocation } from "react-router-dom";

const MyComponent = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [btnText, setBtnText] = useState("Start Identity Verification Process");
  const { details } = location.state || {};
  const [userPayload, setUserPayload] = useState(
    JSON.parse(localStorage.getItem("userPayload")) || ""
  );
  const isVerificationExist = sessionStorage.getItem("is_verified");

  useEffect(() => {
    if (
      isVerificationExist &&
      userPayload &&
      sessionStorage.getItem("visitorId") === userPayload.visitor_id
    ) {
      setBtnText("Initiate Liveness Check");
    } else {
      setBtnText("Start Identity Verification Process");
    }
  }, [isVerificationExist, userPayload]);

  const handleProceed = () => {
    if (
      isVerificationExist &&
      userPayload &&
      sessionStorage.getItem("visitorId") === userPayload.visitor_id
    ) {
      const updatedUserPayload = {
        ...userPayload,
        booking_type: "appointment",
      };
      setUserPayload(updatedUserPayload);
      setBtnText("Initiate Liveness Check");
      localStorage.setItem("userPayload", JSON.stringify(updatedUserPayload));
      navigate(pages.checkLiveliness.route, {
        state: {
          visitor_id: details.visitor_id,
        },
      });
    } else {
      const destination = `${pages.Verification.route}?visitor_id=${details.visitor_id}&id=${details.id}&booking_type=appointment`;
      navigate(destination);
    }
  };

  const theme = useTheme();

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title="Requested Appointment Details"
      />

      <Box sx={{ padding: theme.spacing(3) }}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="left"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            >
              Your email:
            </Typography>
            <Typography variant="body1">{details.email_id}</Typography>
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              Requested Appointment Date:
            </Typography>
            <Typography variant="body1">{details.date}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              Meeting Start Time:
            </Typography>
            <Typography variant="body1">{details.start_time}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              Meeting End Time:
            </Typography>
            <Typography variant="body1">{details.end_time}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              Premise:
            </Typography>
            <Typography variant="body1">{details.office_name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              department:
            </Typography>
            <Typography variant="body1">{details.department_name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              officer name:
            </Typography>
            <Typography variant="body1">{details.officer_name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              office address:
            </Typography>
            <Typography variant="body1">{details.office_address}</Typography>
          </Grid>
        </Grid>
      </Box>

      <LoadingButton onClick={handleProceed}>{btnText}</LoadingButton>

      <Grid container spacing={2}>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              paddingTop: "8px",
              textAlign: "left",
              color: "#777",
              width: "100%",
            }}
          >
            Note: Your appointment request will only be submitted after
            completion of "Identity Verification Process".
          </Typography>
        </Grid>
      </Grid>
    </OuterBox>
  );
};

export default MyComponent;
