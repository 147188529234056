import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import { useAlert } from "../../hooks";
import { LoadingButton } from "./../../components";
import TextField from "./../../components/TextField";

const MobileNumberInput = ({
  loading,
  onSubmit,
  setMobileNumber,
  mobileNumber,
  handleUserConsentChange,
  handleGoBack,
}) => {
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const handleMobileNumberChange = (event) => {
    const inputNumber = event.target.value;

    // Remove any non-digit characters from the input
    const cleanedInput = inputNumber.replace(/\D/g, "");

    // Check if the cleaned input starts with 6, 7, 8, or 9
    if (/^[6-9]/.test(cleanedInput) || cleanedInput === "") {
      setMobileNumber(cleanedInput);
    }
  };

  const handleGetOTP = () => {
    if (mobileNumber.length !== 10 || !/^[0-9]+$/.test(mobileNumber)) {
      // console.log(mobileNumber);
      dispatch(
        alert({
          type: "error",
          message: "Please enter a valid 10-digit mobile number.",
        })
      );
      return;
    }
    sessionStorage.setItem("mobileNumber", mobileNumber);
    onSubmit(mobileNumber);
  };

  return (
    <form>
      <TextField
        label="Enter Your 10 Digit Mobile Number"
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter Your 10 Digit Mobile Number"
        inputProps={{
          type: "tel",
          maxLength: 10,
          pattern: "[6-9]\\d*", // Pattern to match numbers starting with 6, 7, 8, or 9
        }}
        value={mobileNumber}
        onChange={handleMobileNumberChange}
      />

      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: "rgb(161, 130, 74)",
              "&.Mui-checked": {
                color: "rgb(161, 130, 74)",
              },
            }}
          />
        }
        label="Are you available on Whatsapp?"
        onClick={handleUserConsentChange}
        style={{ fontWeight: "900 !important" }}
      />
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <LoadingButton
          loading={loading}
          backgroundColor="#009963"
          onClick={handleGetOTP}
          style={{ padding: "10px 12.5px", fontWeight: 700 }}
        >
          Get OTP
        </LoadingButton>

        <LoadingButton
          onClick={handleGoBack}
          backgroundColor="#F5F0E5"
          style={{ padding: "10px 14.5px", color: "black", fontWeight: 700 }}
        >
          Back
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default MobileNumberInput;
