import React from "react";
import MuiLoadingButton from "@mui/lab/LoadingButton";

const LoadingButton = ({
  float = "right",
  fullWidthMobile = false,
  loading = false,
  disabled = loading,
  backgroundColor = "#009963",
  ...props
}) => (
  <MuiLoadingButton
    {...props}
    variant="contained"
    color="primary"
    sx={{
      fontSize: "16px",
      float: float,
      fontWeight: 600,
      fontStyle: "normal",
      borderRadius: "24px",
      backgroundColor: loading ? backgroundColor : backgroundColor,
      marginTop: "16px",
      marginBottom: "16px",
      color: "#FFF",
      width: "100%",
      "@media (min-width: 1000px)": {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: loading ? backgroundColor : backgroundColor,
        borderRadius: "24px",
      },
    }}
    disabled={disabled}
    loading={loading}
  >
    {props.children}
  </MuiLoadingButton>
);

export default LoadingButton;
