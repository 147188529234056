import * as Yup from "yup";

export const formData = {
  email0: "",
  department: "",
  subDepartment: "",
  office: "",
  officer: "",
  premises: "",
  date: null,
  time: [],
  state: "",
  district: "",
  reason: "",
  organization: "",
  memberCount: 1,
  designation: "",
  documentFile: null,
  PurposeVisit: "",
  mobile0: sessionStorage.getItem("mobileNumber"),
};

export const validationSchema = Yup.object().shape({
  premises: Yup.string().required(),
  email0: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid email format"
    )
    .required("Email is required"),
  department: Yup.string().required("Department is required"),
  subDepartment: Yup.string().required("Sub Department is required"),
  PurposeVisit: Yup.string().required("Purpose of Visit is required"),

  officer: Yup.string().required("Officer is required"),
  date: Yup.date().required("Date is required"),
  time: Yup.array().required("Time is required"),
  reason: Yup.string().when("PurposeVisit", {
    is: (purpose) => purpose === "other",
    then: Yup.string().required("Reason is required"),
    otherwise: Yup.string("Official", "Personal"),
  }),
  state0: Yup.string().required("State is required"),
  district0: Yup.string().required("District is required"),
  organization: Yup.string(),
  designation: Yup.string(),
  documentFile: Yup.mixed().notRequired(),
});
