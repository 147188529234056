import styled from "styled-components";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";

import pages from "./../../constants/pages";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: [100%, 100%, 100%, 800px];
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
  height: 100%;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const OptionBox = styled(Grid)`
  background: black;
  color: black;
  width: 85%;
  height: 200px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #ffffff 0%, #ace0f9 100%);
  box-shadow: #b0e5ffb8 3px 3px 15px 0px;
  border: 3px solid #dcf0f9;
  border-radius: 17.5px;
  margin-top: 35px;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-evenly;
    width: 45%;
  }
`;

const Img = styled.img`
  width: 22.5%;
  @media screen and (min-width: 700px) {
    width: 15%;
  }
`;

function HomeComponent() {
  const navigate = useNavigate();

  return (
    <Fragment>
      <OuterBox>
        <OuterPaper>
          <Grid
            container
            justifyContent={"center"}
            sx={{
              "@media(min-width: 700px)": { justifyContent: "space-evenly" },
            }}
          >
            {[
              {
                name: "Book Event",
                logo: "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1727943944680_event.png",
                click: function () {
                  navigate(pages.eventListPage.route);
                },
              },
              {
                name: "Book Appointment",
                logo: "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1727949449672_%E2%80%94Pngtree%E2%80%94business%20deadline%20icon_3700437.png",
                click: function () {
                  navigate(`${pages.appointment.route}bookappointment`);
                },
              },
              {
                name: "Shop And More",
                logo: "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a06ed04d3ab645418731c85668acecca/1729600846528_1568366-200.png",
                click: function () {
                  navigate(`${pages.ShopsMore.route}`);
                },
              },
            ].map((item, index) => {
              return (
                <OptionBox item container onClick={item.click}>
                  <Img src={item.logo} />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "900",
                      "-webkit-text-stroke-width": ".75px",
                      "@media(min-width: 700px)": {
                        fontSize: ".9rem",
                      },
                    }}
                  >
                    {item.name}
                  </Typography>
                </OptionBox>
              );
            })}
          </Grid>
        </OuterPaper>
      </OuterBox>
    </Fragment>
  );
}

export default HomeComponent;
