import React, { Fragment } from "react";
import {
  CardContent,
  CardMedia,
  Typography,
  Button,
  Link,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import LocationIcon from "@mui/icons-material/LocationOn";

import Dummy from "./../assests/dummy.png";

const EventCard = ({ eventObject, handleDownloadPDF }) => {
  return (
    <Fragment>
      <CardMedia
        component="img"
        sx={{
          padding: "15px",
          height: "350px",
          width: "100%",
          borderRadius: "25px",
          objectFit: "fill",
        }}
        image={eventObject.banner_image ? eventObject.banner_image : Dummy}
        alt={eventObject.name}
      />

      <CardContent
        sx={{
          padding: "17.5px 10px",
          border: "1px solid rgb(232, 222, 207)",
          borderRadius: "12.5px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flex: "1" }}>
            <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
              <Grid item xs={12} sm={6}>
                {eventObject.address.map_link ? (
                  <Tooltip title="View on Map">
                    <Link
                      href={`${eventObject.address.map_link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      underline="none"
                    >
                      <Box display="flex" alignItems="center">
                        <LocationIcon
                          sx={{
                            marginRight: "4px",
                            color: "blue",
                            color: "rgb(191 157 82)",
                          }}
                        />
                        <Typography
                          variant="body2"
                          fontWeight={900}
                          color="rgb(173 119 0)"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {eventObject.address.full_address}
                        </Typography>
                      </Box>
                    </Link>
                  </Tooltip>
                ) : (
                  <Box display="flex" alignItems="center">
                    <LocationIcon
                      sx={{ marginRight: "4px", color: "rgb(191 157 82)" }}
                    />
                    <Typography
                      variant="body2"
                      fontWeight={900}
                      color="rgb(173 119 0)"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {eventObject.address.full_address}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          {eventObject.brochure && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => handleDownloadPDF(eventObject.brochure)}
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginTop: { xs: "8px", sm: 0 },
                backgroundColor: "rgb(245, 240, 229)",
                color: "rgb(183 141 50)",
                borderRadius: "12.5px",
                "&:hover": {
                  backgroundColor: "rgb(245, 240, 229)",
                  borderRadius: "12.5px",
                },
              }}
            >
              Download Brochure
            </Button>
          )}
        </Box>
      </CardContent>
    </Fragment>
  );
};

export default EventCard;
