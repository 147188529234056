import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import ErrorLogo from "./assests/error.svg";
import { SubheaderSection, LoadingButton } from "./../../components";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    // boxShadow: "rgb(240, 94, 7, 0.75) 0px 5px 6px 1px",
    height: "auto",
    background: "rgb(255, 255, 255)",
    margin: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "45%",
    },
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
}));

const StyledImage = styled("img")(({ theme }) => ({
  maxWidth: "96px",
  height: "auto",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "72px",
  },
}));

const Error = ({
  open,
  setOpen,
  ErrorMessage = "Ooops! Something Went Wrong Try Again",
  handleOnClick,
  buttonContent,
  CloseButton = true,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledDialog
      open={open}
      onClose={CloseButton && (() => setOpen(false))}
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <DialogContent>
        <ContentBox>
          {CloseButton && (
            <Grid item container justifyContent={"end"} xs={12}>
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ fontSize: "35px" }}
              />
            </Grid>
          )}
          <StyledImage src={ErrorLogo} alt="Error" />
          <SubheaderSection
            textAlign="center"
            title="Oops"
            titleColor="#F05E4B"
            // content="Looks like there is some error, we request you to please retry"
          />
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.4rem",
              },

              padding: {
                xs: "0px 30px",
                sm: "0px 30px",
                md: "0px",
              },
            }}
          >
            {ErrorMessage}
          </Typography>
        </ContentBox>
      </DialogContent>
      {buttonContent && (
        <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
          <LoadingButton
            backgroundColor="#F05E4B"
            onClick={handleOnClick}
            sx={{
              width: {
                xs: "90%",
                sm: "auto",
              },
            }}
          >
            {buttonContent}
          </LoadingButton>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export default Error;
