let hotelData = [
  {
    category: "Hotels",
    filter:
      "Mövenpick Hotel Stuttgart Airport,Business Hotel,4-star superior,Conference Rooms,Wellness",
    status: "open",
    security: "True",
    name: "Mövenpick Hotel Stuttgart Airport",
    hours: "Open 24 hours",
    description:
      "An exclusive business hotel with exceptional architecture, located directly at Stuttgart Airport & Messe. This 4-star superior hotel offers 326 rooms, 12 junior suites, and two 71 m² suites, combining an elegant business look with cosy accessories.",
    amenities: {
      restaurant: {
        name: "Trollinger",
        seating: "174",
        features:
          "Fireplace, private dining area, international and Swiss dishes, regional specialties",
      },
      conference: {
        rooms: "16",
        ballroom_size: "564 m²",
        max_capacity: "500 people",
        features: "Natural light, ideal for events",
      },
      wellness: {
        gym_size: "400 m²",
        features:
          "Fitness room, saunas, steam bath, Kneipp pool, massage and cosmetic services",
      },
    },
    location: {
      place: "Terminal 1, Departures",
      secure_area: "Inside security area",
    },
    contact: "+49 711 978850",
  },
  {
    category: "Hotels",
    filter: "Mövenpick Hotel Stuttgart Airport,Luxury Hotel,Airport,Wellness",
    status: "open",
    hours: "Open 24 hours",
    security: "False",
    name: "Mövenpick Hotel Stuttgart Airport",
    description:
      "Located at Stuttgart Airport, the Mövenpick Hotel offers luxurious accommodations and superb interior design. Guests can enjoy international cuisine at the Trollinger restaurant or relax in the 400 m² wellness area, featuring fitness and spa facilities.",
    amenities: {
      restaurant: {
        name: "Trollinger",
        seating: "174",
        features:
          "Fireplace, private dining area, international and Swiss dishes, regional specialties",
      },
      conference: {
        rooms: "16",
        ballroom_size: "564 m²",
        max_capacity: "500 people",
        features: "Natural light, ideal for business events",
      },
      wellness: {
        gym_size: "400 m²",
        features:
          "Saunas, steam bath, fitness room, Kneipp pool, massage services",
      },
    },
    location: {
      place: "Terminal 2, Arrivals",
      secure_area: "In the public sector",
    },
    contact: "+49 711 978850",
  },
  {
    category: "Hotels",
    filter:
      "Mövenpick Hotel Stuttgart Airport,Exclusive Business Hotel,Wellness,Airport",
    status: "open",
    security: "False",
    hours: "Open 24 hours",
    name: "Mövenpick Hotel Stuttgart Airport",
    description:
      "Mövenpick Hotel Stuttgart Airport is a luxurious 4-star superior hotel with 326 rooms, ideal for business travelers and event organizers. It offers 16 multi-functional conference rooms, a fitness and wellness area, and gourmet dining at the Trollinger restaurant.",
    amenities: {
      restaurant: {
        name: "Trollinger",
        seating: "174",
        features:
          "Fireplace, international and Swiss dishes, regional specialties",
      },
      conference: {
        rooms: "16",
        ballroom_size: "564 m²",
        max_capacity: "500 people",
        features: "Natural light, suitable for conferences and meetings",
      },
      wellness: {
        gym_size: "400 m²",
        features: "Spa services, fitness room, saunas, steam bath, Kneipp pool",
      },
    },
    location: {
      place: "Terminal 3, Departures",
      secure_area: "In the public sector",
    },
    contact: "+49 711 978850",
  },
  {
    category: "Hotels",
    filter: "Mövenpick Hotel Stuttgart Airport,Airport Hotel,Business,Wellness",
    status: "open",
    security: "True",
    hours: "Open 24 hours",
    name: "Mövenpick Hotel Stuttgart Airport",
    description:
      "Located right at Stuttgart Airport, the Mövenpick Hotel offers exclusive business accommodations with luxurious amenities, including a spacious gym, wellness area, and the Trollinger restaurant. Ideal for travelers and event organizers.",
    amenities: {
      restaurant: {
        name: "Trollinger",
        seating: "174",
        features:
          "Private dining, fireplace, international and Swiss cuisine, regional specialties",
      },
      conference: {
        rooms: "16",
        ballroom_size: "564 m²",
        max_capacity: "500 people",
        features: "Natural light, multi-functional rooms for events",
      },
      wellness: {
        gym_size: "400 m²",
        features:
          "Saunas, fitness center, steam bath, Kneipp pool, massage services",
      },
    },
    location: {
      place: "Terminal 4, Arrivals",
      secure_area: "Inside security area",
    },
    contact: "+49 711 978850",
  },
];

export default hotelData;
