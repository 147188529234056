import { Fragment, useEffect, useState } from "react";
import RenderBoardingPass from "./RenderBoardingPass";
import { Box, Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import QRCode from "./QRcode";
import { useAxios } from "../../hooks";
import { useLocation } from "react-router-dom";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

export default function BoardingPass() {
  const axios = useAxios();
  const location = useLocation();

  const [boardingPassList, setBoardingPassList] = useState([]);
  const [addBoardingPass, setAddBoardingPass] = useState(false);

  useEffect(() => {
    axios({
      url: "/event/visitor/get-boarding-pass?visitor_id=" + location?.state,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
    }).then((response) => {
      if (response.status) {
        setBoardingPassList(response.data);
      }
    });
  }, [addBoardingPass]);

  return (
    <Fragment>
      <OuterBox>
        <OuterPaper>
          {!addBoardingPass ? (
            <Fragment>
              {boardingPassList.length !== 0 ? (
                <Fragment>
                  {boardingPassList.map((item) => {
                    return <RenderBoardingPass detail={item} />;
                  })}
                </Fragment>
              ) : (
                <Typography
                  textAlign={"center"}
                  fontWeight={"900"}
                  sx={{ m: "50px 0px" }}
                >
                  No Boarding Pass Added
                </Typography>
              )}

              <Grid container justifyContent={"center"}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                  onClick={() => setAddBoardingPass(true)}
                >
                  <AddIcon /> Add Boarding Pass
                </Button>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <QRCode setAddBoardingPass={setAddBoardingPass} />
            </Fragment>
          )}
        </OuterPaper>
      </OuterBox>
    </Fragment>
  );
}
