import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import {
  TextField,
  SelectField,
  MandatoryNote,
  LoadingButton,
  CommonTextField,
} from "../../../../components";
import EventCard from "../EventCard";
import { useAxios } from "../../../../hooks";

import { TiTicket } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";

function Form({ formik, eventID, eventObject, stateOptions, subEventOptions }) {
  const navigate = useNavigate();
  const axiosApi = useAxios({ baseURL: "mox_api_2" });

  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [memberCount, setMemberCount] = useState(formik.values.memberCount);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [districtOptions, setDistrictOptions] = useState([]);

  const handleInputChange = (e) => {
    const regex = /^[A-Za-z\s]*$/;

    const inputValue = e.target.value;

    if (regex.test(inputValue)) {
      formik.handleChange(e);
    }
  };

  const handleDownloadPDF = (link) => {
    const pdfLink = link;

    window.open(pdfLink, "_blank");
  };

  const handleSubEventSelect = (ID, index) => {
    const selectedEventId = ID;

    const selectedEvent = subEventOptions.find(
      (event) => event?._id === selectedEventId
    );

    formik.setFieldValue(
      `subEvent${index}`,
      formik.values.subEvent ? "" : selectedEvent?._id
    );

    formik.setFieldValue(
      `subEventAmount${index}`,
      formik.values.subEventAmount ? 0 : parseInt(selectedEvent?.price)
    );

    if (eventID && !formik.values.eventAmount) {
      formik.setFieldValue("eventAmount", parseInt(eventObject.ticket_cost));
    }
  };

  const handleStateChange = (event, index) => {
    setDistrictOptions([]);

    const selectedState = event.target.value;

    formik.setFieldValue(`state${index}`, selectedState);

    if (eventID && !formik.values.eventAmount) {
      formik.setFieldValue("eventAmount", eventObject.ticket_cost);
    }

    formik.setFieldValue(`district${index}`, "");

    fetchDistricts(selectedState);
  };

  const fetchDistricts = async (selectedState) => {
    try {
      const response = await axiosApi({
        url: `event/booking/districts?state_id=${selectedState}`,
        method: "GET",
      });
      if (response.status) {
        const formattedDistricts = response.data.map((district) => ({
          id: district?.district_id,
          name: district?.district_name,
        }));

        setDistrictOptions(formattedDistricts);
      }
    } catch (error) {
      console.error("Error in fetching district list", error);
    }
  };

  function handleAddMember() {
    setMemberCount(memberCount + 1);
    formik.setFieldValue("memberCount", memberCount + 1);
  }

  function handleRemoveMember() {
    setMemberCount(memberCount - 1);
    formik.setFieldValue("memberCount", memberCount - 1);
  }

  useEffect(() => {
    if (formik.values.state0) {
      fetchDistricts(formik.values.state0);
    }
    // setMemberCount();
  }, []);

  return (
    <Fragment>
      <Grid
        container
        spacing={3}
        sx={{
          textTransform: "capitalize",
          // boxShadow: "1px 1px 4px 3px rgb(215 156 49)",
        }}
      >
        {eventObject.event_name && (
          <Grid item xs={12} sm={12}>
            <EventCard
              eventObject={eventObject}
              handleDownloadPDF={handleDownloadPDF}
            />
          </Grid>
        )}

        <Grid
          item
          container
          spacing={3}
          m={"10px 0px"}
          sx={{
            boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
            padding: "10px 10px 10px 0px !important",
            borderRadius: "12.5px",
            border: "1px solid rgb(232, 222, 207)",
          }}
        >
          <Grid item xs={6}>
            <TextField
              name="name0"
              label={"Name"}
              formikProps={formik}
              disabled={
                Boolean(state?.first_name) ||
                formik.values.paymentStatue === "Pending"
              }
            />
          </Grid>

          <SelectField
            label="Select Your Gender"
            name="gender0"
            value={formik.values.gender0}
            options={[
              { id: "Male", name: "Male" },
              { id: "Female", name: "Female" },
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.district && formik.errors.district}
            required
            disabled={formik.values.paymentStatue === "Pending"}
          />

          <Grid mt={1} item xs={12}>
            <TextField
              name="mobile0"
              label={"Mobile No"}
              formikProps={formik}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <CommonTextField
              label="Enter Your Email"
              placeholder="Enter Your Email"
              name="email0"
              type="email"
              value={formik.values.email0}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email0 && Boolean(formik.errors.email0)}
              required
              disabled={formik.values.paymentStatue === "Pending"}
            />
          </Grid>

          <SelectField
            label="Choose Your State"
            value={formik.values[`state0`]}
            onChange={(e) => handleStateChange(e, 0)}
            options={stateOptions}
            error={formik.touched[`state0`] && Boolean(formik.errors[`state0`])}
            required
            disabled={formik.values.paymentStatue === "Pending"}
          />

          <SelectField
            label="Choose Your District"
            name="district0"
            value={formik.values[`district0`]}
            options={districtOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[`district0`] && formik.errors[`district0`]}
            required
            disabled={formik.values.paymentStatue === "Pending"}
          />

          {subEventOptions.length > 0 && (
            <Fragment>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  mb={3}
                  fontWeight={700}
                  color={"rgb(215, 139, 0)"}
                >
                  Select Event Badge
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                display={"flex"}
                width={0}
                sx={{
                  border: " 1px solid rgb(232, 222, 207)",
                  borderRadius: "12.5px",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  flexDirection: "row",
                  padding: "10px 5px !important",
                }}
              >
                {subEventOptions.map((items, index) => {
                  return (
                    <div
                      style={{
                        margin: "5px",
                        background: items.color,
                        padding: "10px 20px",
                        borderRadius: "12.5px",
                        boxShadow:
                          formik.values.subEvent0 === items._id &&
                          "rgb(49, 49, 48) 0px 0px 1px 2px",
                        pointerEvents:
                          formik.values.paymentStatue === "Pending" && "none",
                      }}
                      onClick={() => handleSubEventSelect(items?._id, 0)}
                    >
                      <Typography sx={{ fontWeight: 900, textAlign: "start" }}>
                        {items.badge_name}
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 900,
                          textAlign: "start",
                          color: "rgb(233 175 71)",
                        }}
                      >
                        Rs {items.price}
                      </Typography>
                    </div>
                  );
                })}
              </Grid>
            </Fragment>
          )}

          <Grid item xs={6}>
            <CommonTextField
              label="Enter Your organization's Name(Optional)"
              placeholder="Enter Your organization's Name(Optional)"
              name="organization"
              type="text"
              value={formik.values.organization}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              disabled={formik.values.paymentStatue === "Pending"}
            />
          </Grid>

          <Grid item xs={6}>
            <CommonTextField
              label="Enter Your Designation(Optional)"
              placeholder="Enter Your Designation Name(Optional)"
              name="designation"
              type="text"
              value={formik.values.designation}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              disabled={formik.values.paymentStatue === "Pending"}
            />
          </Grid>
        </Grid>

        {Array(memberCount)
          .fill(true)
          .map((item, index) => {
            return (
              <Grid
                item
                container
                spacing={3}
                m={"20px 0px"}
                sx={{
                  boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
                  padding: "10px 10px 10px 0px !important",
                  borderRadius: "12.5px",
                  border: "1px solid rgb(232, 222, 207)",
                  position: "relative",
                }}
              >
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "space-between",
                    position: "absolute",
                    top: "-30px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      alignSelf: "center",
                      color: "rgb(215 156 49)",
                      background: "white",
                      padding: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {index + 1} Person
                  </Typography>
                  {!Boolean(formik.values.paymentStatue === "Pending") && (
                    <AiOutlineDelete
                      style={{
                        color: "white",
                        padding: "3px",
                        fontSize: "35px",
                        backgroundColor: "#ff9191",
                        backgroundImage:
                          "linear-gradient(164deg, #ff9191 0%, #FF2525 74%)",
                        borderRadius: "5px",

                        boxShadow: "1px 2px 4px 1px #bbbaba",
                      }}
                      onClick={handleRemoveMember}
                    />
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name={`name${index + 1}`}
                    label={"Name"}
                    formikProps={formik}
                    disabled={formik.values.paymentStatue === "Pending"}
                  />
                </Grid>

                <SelectField
                  label="Select Your Gender"
                  name={`gender${index + 1}`}
                  value={formik.values[`gender${index + 1}`]}
                  options={[
                    { id: "Male", name: "Male" },
                    { id: "Female", name: "Female" },
                  ]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.district && formik.errors.district}
                  required
                  disabled={formik.values.paymentStatue === "Pending"}
                />

                <CommonTextField
                  label="Enter Your Email"
                  placeholder="Enter Your Email"
                  name={`email${index + 1}`}
                  type="email"
                  value={formik.values[`email${index + 1}`]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`email${index + 1}`] &&
                    Boolean(formik.errors[`email${index + 1}`])
                  }
                  required
                  disabled={formik.values.paymentStatue === "Pending"}
                />

                <Grid mt={1} item xs={12}>
                  <TextField
                    name={`mobile${index + 1}`}
                    label={"Mobile No"}
                    formikProps={formik}
                    disabled={formik.values.paymentStatue === "Pending"}
                  />
                </Grid>

                <SelectField
                  label="Choose Your State"
                  value={formik.values[`state${index + 1}`]}
                  onChange={(e) => handleStateChange(e, index + 1)}
                  options={stateOptions}
                  error={
                    formik.touched[`state${index + 1}`] &&
                    Boolean(formik.errors[`state${index + 1}`])
                  }
                  required
                  disabled={formik.values.paymentStatue === "Pending"}
                />

                <SelectField
                  label="Choose Your District"
                  name={`district${index + 1}`}
                  value={formik.values[`district${index + 1}`]}
                  options={districtOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`district${index + 1}`] &&
                    formik.errors[`district${index + 1}`]
                  }
                  required
                  disabled={formik.values.paymentStatue === "Pending"}
                />

                {subEventOptions.length > 0 && (
                  <Fragment>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        mb={3}
                        fontWeight={700}
                        color={"rgb(215, 139, 0)"}
                      >
                        Select Event Badge
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      width={0}
                      sx={{
                        border: " 1px solid rgb(232, 222, 207)",
                        borderRadius: "12.5px",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        flexDirection: "row",
                        padding: "10px 5px !important",
                      }}
                    >
                      {subEventOptions.map((items, indx) => {
                        return (
                          <div
                            style={{
                              margin: "5px",
                              background: items.color,
                              padding: "10px 20px",
                              borderRadius: "12.5px",
                              boxShadow:
                                formik.values[`subEvent${index + 1}`] ===
                                  items._id &&
                                "rgb(49, 49, 48) 0px 0px 1px 2px",

                              pointerEvents:
                                formik.values.paymentStatue === "Pending" &&
                                "none",
                            }}
                            onClick={() =>
                              handleSubEventSelect(items?._id, index + 1)
                            }
                          >
                            <Typography
                              sx={{ fontWeight: 900, textAlign: "start" }}
                            >
                              {items.badge_name}
                            </Typography>

                            <Typography
                              sx={{
                                fontWeight: 900,
                                textAlign: "start",
                                color: "rgb(233 175 71)",
                              }}
                            >
                              Rs {items.price}
                            </Typography>
                          </div>
                        );
                      })}
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            );
          })}

        <Grid item container>
          <LoadingButton
            loading={loading}
            fullWidth
            backgroundColor="rgb(215 174 99)"
            style={{
              fontWeight: 800,
              margin: "2px 0px",
              color: "#ffffff",
              "-webkit-text-stroke-width": "1px",
              wordSpacing: " 3px",
              letterSpacing: "2px",
            }}
            onClick={handleAddMember}
            disabled={
              memberCount > 3 || formik.values.paymentStatue === "Pending"
            }
          >
            Add More Member
          </LoadingButton>
        </Grid>

        {Boolean(formik.values.eventAmount || formik.values.subEventAmount) && (
          <Grid item container>
            <table
              style={{
                width: "100%",
                border: "1px solid rgb(232, 222, 207)",
                padding: "10px",
                borderRadius: "5px",
                position: "relative",
                boxShadow: "3px 3px 5px -2px rgb(232 196 144)",
              }}
            >
              <span
                class="__circle-left"
                style={{
                  display: "inline-block",
                  position: "absolute",
                  top: "45%",
                  zIndex: "1",
                  width: "22px",
                  height: "22px",
                  background: "#ffffff",
                  borderRadius: "50%",
                  left: "-11px",
                  boxShadow: "inset -2px 0 0 rgb(232, 222, 207)",
                }}
              ></span>
              <span
                class="__circle-right"
                style={{
                  display: "inline-block",
                  position: "absolute",
                  top: "40%",
                  zIndex: "1",
                  width: "22px",
                  height: "22px",
                  background: "#ffffff",
                  borderRadius: "50%",
                  right: "-11px",
                  boxShadow: "inset 2px 0 0 rgb(232, 222, 207)",
                }}
              ></span>
              <thead>
                <tr>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Event Ticket
                  </td>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "600",
                      color: "black",
                    }}
                  ></td>
                </tr>
              </thead>

              <tbody>
                {Boolean(formik.values.eventAmount) && (
                  <tr>
                    <td>
                      <TiTicket
                        style={{ fontSize: "19px", verticalAlign: "top" }}
                      />{" "}
                      {formik.values.memberCount + 1}&nbsp; &#10006; &nbsp;
                      {eventObject?.event_name}&nbsp; - &nbsp; ₹
                      {formik.values.eventAmount}
                    </td>
                    <td style={{ textAlign: "end" }}>
                      ₹
                      {formik.values.eventAmount *
                        (formik.values.memberCount + 1)}
                    </td>
                  </tr>
                )}

                {Array(formik.values.memberCount + 1)
                  .fill(true)
                  .map((item, index) => {
                    return (
                      <Fragment>
                        {formik.values[`subEvent${index}`] && (
                          <tr>
                            <td>
                              <TiTicket
                                style={{
                                  fontSize: "19px",
                                  verticalAlign: "top",
                                }}
                              />{" "}
                              1&nbsp; &#10006; &nbsp;{" "}
                              {
                                subEventOptions.filter((item) => {
                                  return (
                                    item._id ==
                                    formik.values[`subEvent${index}`]
                                  );
                                })[0]?.badge_name
                              }{" "}
                              &nbsp;- &nbsp;₹{" "}
                              {formik.values[`subEventAmount${index}`]}
                            </td>
                            <td style={{ textAlign: "end" }}>
                              ₹ {formik.values[`subEventAmount${index}`]}
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })}

                <tr>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "700",
                      color: "black",
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "700",
                      color: "black",
                      textAlign: "end",
                    }}
                  >
                    ₹{" "}
                    {parseInt(
                      formik.values.eventAmount *
                        (formik.values.memberCount + 1)
                    ) +
                      Array(formik.values.memberCount + 1)
                        .fill(true)
                        .reduce(
                          (sum, current, index) =>
                            (formik?.values[`subEventAmount${index}`]
                              ? formik?.values[`subEventAmount${index}`]
                              : 0) + sum,
                          0
                        )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        )}
      </Grid>

      <MandatoryNote />

      <Grid item container spacing={{ sl: 2 }}>
        <Grid item xs={12} sl={6}>
          <LoadingButton
            loading={loading}
            onClick={formik.handleSubmit}
            disabled={uploadLoading || !formik.dirty || !formik.isValid}
            fullWidth
            backgroundColor="#009963"
            style={{ fontWeight: 800 }}
          >
            {`${
              formik.values.eventAmount + formik.values?.subEventAmount == 0
                ? "Take Next Step"
                : "Proceed to Pay"
            }`}

            {Boolean(
              formik.values.eventAmount + formik.values?.subEventAmount
            ) &&
              `: INR ${
                parseInt(
                  formik.values.eventAmount * (formik.values.memberCount + 1)
                ) +
                Array(formik.values.memberCount + 1)
                  .fill(true)
                  .reduce(
                    (sum, current, index) =>
                      (formik?.values[`subEventAmount${index}`]
                        ? formik?.values[`subEventAmount${index}`]
                        : 0) + sum,
                    0
                  )
              }`}
          </LoadingButton>
        </Grid>

        <Grid item xs={12} sl={6}>
          <LoadingButton
            loading={loading}
            onClick={() => navigate(-1)}
            fullWidth
            backgroundColor="#F5F0E5"
            style={{ fontWeight: 800, color: "black" }}
          >
            Back{" "}
          </LoadingButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Form;
