import { Grid } from "@mui/material";
import React, { useRef } from "react";

import { LoadingButton, ResendOTPButton, OTPInput } from "./../../components";

const OTPVerification = ({
  loading,
  otpLength,
  onSubmit,
  onResend,
  otp,
  setOTP,
  handleOtpGoBack,
}) => {
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < otpLength - 1) {
      newOTP[index + 1] = "";
      setOTP(newOTP);
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {otp.map((value, index) => (
          <OTPInput
            key={index}
            index={index}
            value={value}
            otp={otp}
            inputRefs={inputRefs}
            otpLength={otpLength}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>

      <Grid item container justifyContent={"center"}>
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <LoadingButton
            loading={loading}
            onClick={onSubmit}
            backgroundColor="#009963"
            style={{ padding: "10px 12.5px", fontWeight: 700 }}
          >
            Verify OTP
          </LoadingButton>

          <LoadingButton
            onClick={handleOtpGoBack}
            backgroundColor="#F5F0E5"
            style={{ padding: "10px 14.5px", color: "black", fontWeight: 700 }}
          >
            Back
          </LoadingButton>
        </Grid>
      </Grid>

      <ResendOTPButton onClick={onResend} disabled={loading} />
    </form>
  );
};

export default OTPVerification;
