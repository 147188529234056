import { Fragment } from "react";
import { useLocation } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CompanyFooter from "./footer components/CompanyFooter";
import DashboardFooter from "./footer components/DashboardFooter";

export default function Footer() {
  const location = useLocation();

  const match = [
    "/manual-entry",
    "/resend-notification",
    "/manual-verification",
    "/main-gate-entry-back",
    "/main-gate-entry-front",
  ].filter((item) => location.pathname == item);

  return (
    <Fragment>
      {Boolean(match.length) ? <DashboardFooter /> : <CompanyFooter />}
    </Fragment>
  );
}
