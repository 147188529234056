import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, Box, styled, Typography, Grid } from "@mui/material";

import { useDispatch } from "react-redux";
import { signOutMember } from "../../redux/slices/memberSlice";

import Logo from "./assets/logo3.png";

interface Props {
  window?: () => Window;
}

export default function DrawerAppBar(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const match = [
    "/manual-entry",
    "/resend-notification",
    "/manual-verification",
    "/main-gate-entry-back",
  ].filter((item) => location.pathname == item);

  return (
    <Box
      className="navbarBox"
      sx={{ marginTop: "auto", border: "2px solid rgba(255, 255, 255, 0.4)" }}
    >
      <CssBaseline />

      <Grid item container>
        <Grid item xs={3}></Grid>

        <Grid
          item
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
          }}
          xs={6}
        >
          <Grid item>
            {/* <Typography
              variant="h2"
              fontWeight={900}
              color={"green"}
              style={{
                background:
                  "linear-gradient(to right, #2d622d,  #2d622d, #2d622d, #2d622d)",
                "-webkit-text-fill-color": "transparent",
                "-webkit-background-clip": "text",

                verticalAlign: "middle",
                marginTop: "7.5px",
                marginBottom: "7.5px",
              }}
            >
              digipass
            </Typography> */}
            <img
              src={Logo}
              style={{ height: "60px", marginTop: "15px", width: "250px" }}
            />
          </Grid>
        </Grid>

        {!!match.length && (
          <Grid
            item
            container
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "10px",
              alignItems: "center",
            }}
            xs={3}
          >
            <Button
              variant="contained"
              onClick={() => {
                dispatch(signOutMember());
                navigate("/sign-in");
              }}
              sx={{ background: "red", height: "100%", letterSpacing: "-1px" }}
            >
              Log out
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
