import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Dialog, DialogContent, Typography, Grid } from "@mui/material";

import { SubheaderSection } from "../../components";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    background: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
}));

const ContentBox = styled(Box)({
  padding: "30px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
});

const ImagePopup = ({ open, setOpen, state, userPayload }) => {
  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: "0px" }}>
        <ContentBox>
          <SubheaderSection
            textAlign="center"
            variant="h4"
            title={`Verified Image With (${
              userPayload?.doc_type == 2 ? "DL" : "AADHAR"
            })`}
            titleColor="#2b2b2b"
          />

          <Grid item container>
            <Grid item xs={6}>
              <Typography
                variant={"h6"}
                sx={{
                  fontFamily: "'Poppins'",
                }}
              >
                Docs Image
              </Typography>
              <img
                width="90%"
                height="100px"
                style={{ objectFit: "initial" }}
                src={"data:image/png;base64," + userPayload?.doc_face_image}
                alt="Preview"
                style={{ borderRadius: "8px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant={"h6"}
                sx={{
                  fontFamily: "'Poppins'",
                }}
              >
                Click Image
              </Typography>
              <img
                width="90%"
                height="100px"
                style={{ objectFit: "initial" }}
                src={state?.face_data}
                alt="Preview"
                style={{ borderRadius: "8px", marginLeft: "5px" }}
              />
            </Grid>
          </Grid>

          <Grid item>
            <Typography
              variant={"h5"}
              sx={{
                fontFamily: "'Poppins'",
                textAlign: "center",
                marginTop: "5px",
                fontWeight: "700",
              }}
            >
              Match Percentage {parseInt(state?.match_percentage)} %
            </Typography>
          </Grid>
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default ImagePopup;
