// import ENV from "../config/config";

// const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

// const BASE_URL = {
//   mox_api: {
//     url: "https://" + URL_PREFIX + "api-digipass.dolphinchat.ai",
//     version: "/v1",
//   },
//   aadharUrl: {
//     url: "https://govtapi.kycvideo.in/api/",
//     version: "v1",
//   },
// };

import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";
let adminURL =
  ENV === "main"
    ? "https://backend-digipass.verismart.ai"
    : "https://" + URL_PREFIX + "backend-digipass.verismart.ai";

let adminURL2 =
  ENV === "main"
    ? "https://backend-digipass.verismart.ai"
    : "https://" + URL_PREFIX + "backend-api.verismart.ai";
let analyticURL =
  ENV === "main"
    ? "https://api-digipass.verismart.ai"
    : "https://" + URL_PREFIX + "api-digipass.dolphinchat.ai";

let adminURL3 =
  ENV === "main"
    ? "https://backend-digipass.verismart.ai"
    : "https://" + URL_PREFIX + "backend-digipass.verismart.ai";

const BASE_URL = {
  mox_api: {
    url: adminURL,
    version: "/v1",
  },
  mox_api_2: {
    url: adminURL3,
    version: "/v1",
  },
  digipass: {
    url: adminURL3,
    version: "/v1",
  },
  analytic: {
    url: analyticURL,
    version: "/v1/analytics",
  },

  aadharUrl: {
    url: "https://govtapi.kycvideo.in/api/",
    version: "v1",
  },
};

export default BASE_URL;
