import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { MdOutlineKeyboardAlt } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Grid, Typography, Tooltip } from "@mui/material";
import KeyboardAltRoundedIcon from "@mui/icons-material/KeyboardAltRounded";

import { MdVerified } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";

import { MdDoorSliding } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { MdOutlineDoorSliding } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";

import pages from "../../../constants/pages";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TabButton = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  font-size: 7.5px;
  font-family: "Open Sans, sans-serif";
  font-weight: 400;
  align-items: center;
`;

const OuterBox = styled(Box)`
  box-sizing: border-box;
  min-height: 10vh;
  border-radius: 50px;
`;

const BoxPaper = styled(Paper)`
  width: 50vw;
  margin: 0px auto;
  border-radius: 50px;
  background: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-radius: 50px;
  background: rgb(251 238 213);
  box-shadow: rgb(251, 248, 242) 0px 0px 0px 1px;
  padding: 1.5px;
  @media (max-width: 900px) {
    width: 90%;
  }
`;

export default function DashboardFooter() {
  const navigate = useNavigate();

  const location = useLocation();

  const [selectOption, setSelectOption] = useState(location.pathname);

  function stopCamera() {
    const stream = document.querySelector("video")?.srcObject;
    if (stream) {
      const tracks = stream?.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      document.querySelector("video").srcObject = null;
    }
  }

  const handleNavigation = (route) => {
    if (selectOption !== route) {
      stopCamera();
      setSelectOption(route);
      navigate(route);
    }
  };

  useEffect(() => {
    setSelectOption(location.pathname);
  }, [location]);

  return (
    <OuterBox>
      <BoxPaper>
        <Grid
          item
          container
          justifyContent={"center"}
          xs={4}
          onClick={() => {
            handleNavigation(pages.MainGateEntryWithBackCamera.route);
          }}
          sx={{
            transition: "0.25s ease-out",
            borderRadius: "50px",
            background:
              selectOption === "/main-gate-entry-back" ||
              selectOption === "/main-gate-entry-front"
                ? "white"
                : "rgb(251 238 213)",
          }}
        >
          <Tooltip title="Auto Entry">
            <TabButton>
              {Boolean(
                selectOption !== "/main-gate-entry-back" &&
                  selectOption !== "/main-gate-entry-front"
              ) && (
                <MdOutlineDoorSliding
                  style={{
                    fontSize: "20px",
                    color: Boolean(
                      selectOption !== "/main-gate-entry-back" &&
                        selectOption !== "/main-gate-entry-front"
                    )
                      ? "rgb(179, 115, 0)"
                      : "#151515",
                  }}
                />
              )}

              {!Boolean(
                selectOption !== "/main-gate-entry-back" &&
                  selectOption !== "/main-gate-entry-front"
              ) && (
                <MdDoorSliding
                  style={{
                    fontSize: "20px",
                    color: Boolean(
                      selectOption !== "/main-gate-entry-back" &&
                        selectOption !== "/main-gate-entry-front"
                    )
                      ? "rgb(179, 115, 0)"
                      : "#151515",
                  }}
                />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: Boolean(
                    selectOption !== "/main-gate-entry-back" &&
                      selectOption !== "/main-gate-entry-front"
                  )
                    ? "rgb(179 115 0)"
                    : "#151515",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                Auto
              </Typography>
            </TabButton>
          </Tooltip>
        </Grid>

        <Grid
          item
          container
          justifyContent={"center"}
          xs={4}
          sx={{
            transition: "0.25s ease-out",
            borderRadius: "50px",
            background:
              selectOption === "/manual-entry" ? "white" : "rgb(251 238 213)",
          }}
          onClick={() => {
            handleNavigation(pages.ManualEntry.route);
          }}
        >
          <Tooltip title="Manual Entry">
            <TabButton>
              {!Boolean(selectOption !== "/manual-entry") && (
                <KeyboardAltRoundedIcon
                  style={{
                    fontSize: "20px",
                    color:
                      selectOption !== "/manual-entry"
                        ? "rgb(179, 115, 0)"
                        : "#151515",
                  }}
                />
              )}

              {Boolean(selectOption !== "/manual-entry") && (
                <MdOutlineKeyboardAlt
                  style={{
                    fontSize: "20px",
                    color:
                      selectOption !== "/manual-entry"
                        ? "rgb(179, 115, 0)"
                        : "#151515",
                  }}
                />
              )}
              <Typography
                variant="body1"
                sx={{
                  color:
                    selectOption !== "/manual-entry"
                      ? "rgb(179 115 0)"
                      : "#151515",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                Manual
              </Typography>
            </TabButton>
          </Tooltip>
        </Grid>

        <Grid
          item
          container
          justifyContent={"center"}
          xs={4}
          sx={{
            transition: "0.25s ease-out",
            borderRadius: "50px",
            background:
              selectOption === "/resend-notification"
                ? "white"
                : "rgb(251 238 213)",
          }}
          onClick={() => {
            handleNavigation(pages.ResendNotification.route);
          }}
        >
          {" "}
          <Tooltip title="Resend Notification">
            {" "}
            <TabButton>
              {!Boolean(selectOption !== "/resend-notification") && (
                <IoIosNotifications
                  style={{
                    fontSize: "20px",
                    fontWeight: "900",
                    color:
                      selectOption !== "/resend-notification"
                        ? "rgb(179, 115, 0)"
                        : "#151515",
                  }}
                />
              )}
              {Boolean(selectOption !== "/resend-notification") && (
                <IoMdNotificationsOutline
                  style={{
                    fontSize: "20px",
                    color:
                      selectOption !== "/resend-notification"
                        ? "rgb(179, 115, 0)"
                        : "#151515",
                  }}
                />
              )}
              <Typography
                variant="body1"
                sx={{
                  color:
                    selectOption !== "/resend-notification"
                      ? "rgb(179, 115, 0)"
                      : "#151515",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                Resend
              </Typography>
            </TabButton>
          </Tooltip>
        </Grid>

        <Grid
          item
          container
          justifyContent={"center"}
          xs={4}
          sx={{
            transition: "0.25s ease-out",
            borderRadius: "50px",
            background:
              selectOption === "/manual-verification"
                ? "white"
                : "rgb(251 238 213)",
          }}
          onClick={() => {
            handleNavigation(pages.ManualVerification.route);
          }}
        >
          <Tooltip title="Manual Verification">
            <TabButton>
              {Boolean(selectOption !== "/manual-verification") && (
                <MdOutlineVerified
                  style={{
                    fontSize: "20px",
                    color:
                      selectOption !== "/manual-verification"
                        ? "rgb(179, 115, 0)"
                        : "#151515",
                  }}
                />
              )}

              {!Boolean(selectOption !== "/manual-verification") && (
                <MdVerified
                  style={{
                    fontSize: "20px",
                    color:
                      selectOption !== "/manual-verification"
                        ? "rgb(179, 115, 0)"
                        : "#151515",
                  }}
                />
              )}

              <Typography
                variant="body1"
                sx={{
                  color:
                    selectOption !== "/manual-verification"
                      ? "rgb(179, 115, 0)"
                      : "#151515",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                Verification
              </Typography>
            </TabButton>
          </Tooltip>
        </Grid>
      </BoxPaper>
    </OuterBox>
  );
}
