import { Box } from "@mui/material";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import hotelData from "./HotelData";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h5 {
    font-size: 18px;
    margin: 10px 0;
  }

  .info {
    text-align: center;
  }

  .icons {
    margin-top: 10px;
  }
`;

const HotelView = () => {
  const location = useLocation();

  const [item, setItem] = useState({});

  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");

  useEffect(() => {
    let shopDetail = hotelData.filter((item, index) => {
      return index == id;
    });
    setItem(shopDetail);
  }, [id]);

  return (
    <OuterBox>
      <OuterPaper>
        <ListContainer>
          <ListItem>
            <a>
              <figure className="shops-picture">
                <div>
                  <h5 style={{ textAlign: "center" }}>{item[0]?.name}</h5>
                </div>
              </figure>
              <figure
                style={{ display: "flex", justifyContent: "center" }}
                className="shops-picture"
              >
                <picture>
                  <img
                    src={
                      "https://www.stuttgart-airport.com/media/185592/5_vor_flug_5060_lowres.jpg?anchor=center&mode=crop&width=600&height=400&rnd=131469262140000000"
                    }
                    alt={item[0]?.name}
                  />
                </picture>
              </figure>
              <figure className="shops-picture">
                <div>
                  <div className="icons">
                    <i
                      className="icon-close"
                      role="img"
                      aria-label="closed"
                    ></i>
                    <div
                      className="place"
                      style={{
                        fontWeight: "900",
                        color: "black",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Description
                    </div>
                  </div>
                  <div className="info">
                    <div className="place">{item[0]?.description}</div>
                  </div>
                </div>

                <div>
                  <div className="icons">
                    <i
                      className="icon-close"
                      role="img"
                      aria-label="closed"
                    ></i>
                    <div
                      className="place"
                      style={{
                        fontWeight: "900",
                        color: "black",
                        textAlign: "center",
                        fontSize: "1rem",
                        marginTop: "10px",
                      }}
                    >
                      Restaurant
                    </div>
                  </div>
                  <div className="info">
                    <div className="place">
                      {item[0]?.amenities?.restaurant.features}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="icons">
                    <i
                      className="icon-close"
                      role="img"
                      aria-label="closed"
                    ></i>
                    <div
                      className="place"
                      style={{
                        fontWeight: "900",
                        color: "black",
                        textAlign: "center",
                        fontSize: "1rem",
                        marginTop: "10px",
                      }}
                    >
                      Conference
                    </div>
                  </div>
                  <div className="info">
                    <div className="place">
                      {item[0]?.amenities?.conference.features}
                    </div>
                  </div>
                </div>
              </figure>
            </a>
          </ListItem>
        </ListContainer>
      </OuterPaper>
    </OuterBox>
  );
};

export default HotelView;
