import styled from "styled-components";
import data from "./ShopData";
import { useNavigate } from "react-router-dom";
import pages from "../../../constants/pages";

const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h5 {
    font-size: 18px;
    margin: 10px 0;
  }

  .info {
    text-align: center;
  }

  .icons {
    margin-top: 10px;
  }
`;

const ShopListView = ({ item, index }) => {
  const navigate = useNavigate();
  return (
    <ListContainer>
      {data.map((item, index) => (
        <ListItem key={index}>
          <a
            onClick={() => {
              navigate(pages.Shop.route + "?id=" + index);
            }}
          >
            <figure className="shops-picture">
              <div>
                <h5>Category : {item.category}</h5>
              </div>
            </figure>
            <figure className="shops-picture">
              <picture>
                <img
                  src={
                    "https://www.stuttgart-airport.com/media/185592/5_vor_flug_5060_lowres.jpg?anchor=center&mode=crop&width=600&height=400&rnd=131469262140000000"
                  }
                  alt={item.name}
                />
              </picture>
            </figure>
            <figure className="shops-picture">
              <div>
                <h5>{item.name}</h5>
                <p className="open">
                  <b>
                    Open from {item?.hour}
                    <br />
                  </b>
                </p>
                <div className="icons">
                  <i className="icon-close" role="img" aria-label="closed"></i>
                </div>
                <div className="info">
                  <div className="place">{item.location?.place}</div>
                  <div className="secure-area">
                    {item.location?.secure_area}
                  </div>
                </div>
              </div>
            </figure>
          </a>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default ShopListView;
