import { Box } from "@mui/system";
import styled from "styled-components/macro";
import { Paper, Grid, Typography } from "@mui/material";

import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { PiInstagramLogoLight } from "react-icons/pi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OuterBox = styled(Box)`
  display: flex;
  flexdirection: row;
  justifycontent: center;
  alignitems: center;
  width: [100%, 100%, 100%, 800px];
  margin: 0 auto;
  padding: 0px 17px;
  boxsizing: border-box;
  background: transparent;
  minheight: 12.5%;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  padding: 27px 35px;
  borderradius: 5px;
  aligncontent: center;
  borderradius: 15px;
  background: transparent;
`;

export default function CompanyFooter() {
  return (
    <OuterBox>
      <OuterPaper elevation={3}>
        <Grid
          item
          container
          flexDirection={"column"}
          alignContent={"center"}
          xs={12}
        >
          <Grid item display={"flex"} justifyContent={"center"} mb={5}>
            <CiFacebook
              style={{
                color: "#A1824A",
                marginRight: "10px",
                fontSize: "27px",
              }}
            />
            <CiLinkedin
              style={{
                color: "#A1824A",
                marginRight: "10px",
                fontSize: "27px",
              }}
            />
            <PiInstagramLogoLight
              style={{
                color: "#A1824A",
                marginRight: "10px",
                fontSize: "27px",
              }}
            />
          </Grid>

          <Grid item>
            <Typography
              variant="body1"
              fontWeight={100}
              fontSize={"18px"}
              fontFamily={"Plus Jakarta Sans"}
              color={"#A1824A"}
            >
              Product by Verismart.ai
            </Typography>
          </Grid>
        </Grid>
      </OuterPaper>
    </OuterBox>
  );
}
