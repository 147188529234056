import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import { Fragment, useRef } from "react";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import InfoIcon from "@mui/icons-material/Info";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import { OuterBox, LoadingButton, SubheaderSection } from "./../../components";
import Instruction from "./Instruction";
import EventBookingSuccess from "../Success/EventBookingSuccess";
import Error from "../Error/Error";

const OuterBox2 = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

export default function UploadDocument() {
  const axios = useAxios();
  const navigate = useNavigate();

  const [mimeType, setMimeType] = useState("video/webm;codecs=vp8,opus");
  const axiosDigipass = useAxios({ baseURL: "digipass" });

  const location = useLocation();
  const { visitor_id, id } = location.state || {};

  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get("booking_id");
  const eventId = queryParams.get("event_id");
  const appointmentID = queryParams.get("appointment_id");
  const officerID = queryParams.get("officer_id");

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState({});

  useEffect(() => {
    if (eventId) {
      sessionStorage.setItem("eventID", eventId);

      axiosDigipass({
        url: "admin/events/event-details?event_id=" + eventId,
        method: "GET",
        customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      }).then((response) => {
        setEventDetail(response.data);
      });
    }

    if (window.navigator.userAgent.indexOf("Windows") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else if (window.navigator.userAgent.indexOf("Mac OS") !== -1) {
      setMimeType("video/mp4");
    } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else {
      setMimeType("video/webm;codecs=vp8,opus");
    }

    if (Boolean(bookingId)) {
      axiosDigipass({
        url: "event/verify/booking-status/",
        method: "POST",
        data: { booking_id: bookingId },
        disableRedirect: true,
      }).then((response) => {
        if (response?.status) {
          !response.data.is_valid && setError(response.message.displayMessage);
        } else {
          setError(response?.displayMessage);
          setErrorOpen(true);
        }
      });
    }
  }, []);

  const [startRecording, setStartRecording] = useState(false);

  const verificationContent = startRecording
    ? ""
    : "Face verification is required to confirm your identity by matching your live image with the one associated with your Aadhaar record";

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          height: 620,
          zoom: true,
        },
        audio: false,
      });
      setStream(stream);
      videoRef.current.srcObject = stream;
    } catch (error) {
      if (error.name === "NotReadableError") {
        console.log(
          "NotReadableError: The camera might be in use or not available"
        );
        alert(
          "Unable to access the camera. Please ensure it's not in use by another application and refresh the page."
        );
        window.location.reload();
      } else if (error.name === "OverconstrainedError") {
        console.log(
          "OverconstrainedError: The constraints don't match any installed camera"
        );
        alert("No suitable camera found. Please check your camera settings.");
      } else {
        alert(`Error accessing camera: ${error.message}`);
      }
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null); // or streamRef.current = null;
    }
  };

  const takePhoto = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (
      (canvas.width >= 150 || canvas.width <= 300) &&
      (canvas.height >= 600 || canvas.height <= 1200)
    ) {
      if (video && canvas) {
        const context = canvas.getContext("2d");

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const captureSize = 350;
        const sourceX = (videoWidth - captureSize) / 2;
        const sourceY = (videoHeight - captureSize) / 2;

        context.drawImage(
          video,
          sourceX,
          sourceY,
          captureSize,
          captureSize,
          0,
          0,
          canvas.width,
          canvas.height
        );

        const imageDataURL = canvas.toDataURL("image/jpeg");

        localStorage.setItem("image", imageDataURL);

        const userDocData = JSON.parse(localStorage.getItem("userPayload"));

        axios({
          url: "event/verify/face",
          method: "POST",
          data: {
            doc_face_image: userDocData.doc_face_image,
            captured_face_image: imageDataURL.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            ),
          },
          customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
        }).then((response) => {
          if (response.status) {
            navigate(pages.previewVideo.route, {
              state: {
                blob: imageDataURL,
                visitor_id: visitor_id,
                bookingId: bookingId,
                officerID: officerID,
                appointmentID: appointmentID,
                eventId: eventId || sessionStorage.getItem("eventID"),
                matchPercentage: response.data.match_percentage,
                appointmentData: location.state.appointmentData,
              },
            });
            stopCamera();
          }
        });
      }
    }
  };

  return (
    <Fragment>
      <Fragment>
        {!startRecording && (
          <Grid item container className="eventImageBox">
            <div
              style={{
                width: "100%",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "35vh",
                }}
                src={
                  eventDetail.banner_image ||
                  (Boolean(appointmentID)
                    ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1728475638747_appointment.png"
                    : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a06ed04d3ab645418731c85668acecca/1727690040770_xTk9ZvMnbIiIew7IpW.gif")
                }
              />
            </div>
          </Grid>
        )}

        <form>
          {!startRecording ? (
            <React.Fragment>
              <Grid item container>
                <Grid item container justifyContent={"center"}>
                  {!startRecording && (
                    <Grid item className="companyBanner" xs={6}>
                      <img
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "12.5px" }}
                        src={
                          eventDetail.banner_image ||
                          "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a06ed04d3ab645418731c85668acecca/1727690040770_xTk9ZvMnbIiIew7IpW.gif"
                        }
                      ></img>
                    </Grid>
                  )}

                  <Grid
                    item
                    container
                    md={12}
                    sl={6}
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <OuterBox2>
                      <OuterPaper elevation={3}>
                        <SubheaderSection
                          textAlign={"center"}
                          title={
                            <Fragment>
                              Face Verification{" "}
                              <Tooltip title="Instruction">
                                <InfoIcon
                                  style={{
                                    verticalAlign: "middle",
                                    color: "rgb(219, 141, 0)",
                                  }}
                                  onClick={() => setOpen(true)}
                                />
                              </Tooltip>
                            </Fragment>
                          }
                          content={verificationContent}
                        />
                        <div style={{ marginTop: "25px" }}>
                          <div>
                            <List>
                              <ListItem sx={{ padding: "2px" }}>
                                <TaskAltRoundedIcon
                                  sx={{
                                    color: "rgb(219 141 0)",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText primary="Make sure your camera, microphone, and location services are on." />
                              </ListItem>

                              <ListItem sx={{ padding: "2px" }}>
                                <TaskAltRoundedIcon
                                  sx={{
                                    color: "rgb(219 141 0)",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText primary="Check your internet connection is stable." />
                              </ListItem>
                              <ListItem sx={{ padding: "2px" }}>
                                <TaskAltRoundedIcon
                                  sx={{
                                    color: "rgb(219 141 0)",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText primary="Sit in a well-lit area, avoiding backlighting." />
                              </ListItem>
                              <ListItem sx={{ padding: "2px" }}>
                                <TaskAltRoundedIcon
                                  sx={{
                                    color: "rgb(219 141 0)",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText primary="Look directly into the camera, with your face clearly visible." />
                              </ListItem>
                              <ListItem sx={{ padding: "2px" }}>
                                <TaskAltRoundedIcon
                                  sx={{
                                    color: "rgb(219 141 0)",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText primary="Use a live video feed, not a static photo" />
                              </ListItem>

                              <ListItem sx={{ padding: "2px" }}>
                                <TaskAltRoundedIcon
                                  sx={{
                                    color: "rgb(219 141 0)",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText primary="Ensure only your face is in the camera frame." />
                              </ListItem>
                            </List>
                          </div>
                        </div>

                        <LoadingButton
                          onClick={() => {
                            startCamera();
                            setStartRecording(true);
                          }}
                          style={{
                            width: "auto",
                            padding: "12px 16px",
                          }}
                        >
                          Launch Camera
                        </LoadingButton>
                      </OuterPaper>
                    </OuterBox2>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OuterBox2>
                <OuterPaper elevation={3}>
                  <SubheaderSection
                    textAlign={"center"}
                    title="Face Verification Initiated"
                    content={verificationContent}
                  />
                  <Grid
                    container
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ textAlign: "center", padding: "10px" }}
                      fontWeight="bold"
                    >
                      Make sure to verify the image before submitting
                    </Typography>

                    <Grid
                      item
                      className="videoBox"
                      position={"relative"}
                      width={"30%"}
                    >
                      <svg id="svg-overlay" viewBox="0 0 100 130">
                        <path
                          d="M50 5
                 C 25 5, 5 30, 5 65
                 C 5 90, 15 110, 35 120
                 C 40 123, 45 125, 50 125
                 C 55 125, 60 123, 65 120
                 C 85 110, 95 90, 95 65
                 C 95 30, 75 5, 50 5"
                          fill="none"
                          stroke="rgb(161, 130, 74)"
                          stroke-width="1.5"
                        />
                      </svg>

                      <video
                        id="video"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "initial" }}
                        ref={videoRef}
                        muted
                        autoPlay
                        playsInline
                      ></video>
                    </Grid>

                    <Grid item display={"flex"} justifyContent={"center"}>
                      <LoadingButton
                        id="click-photo"
                        type="button"
                        onClick={takePhoto}
                        style={{
                          padding: "10px 45px",
                        }}
                      >
                        Click Photo
                      </LoadingButton>
                    </Grid>

                    <canvas
                      id="canvas"
                      width="480"
                      height="600"
                      ref={canvasRef}
                      style={{ display: "none" }}
                    ></canvas>
                  </Grid>
                </OuterPaper>
              </OuterBox2>
            </React.Fragment>
          )}
        </form>
      </Fragment>

      <Instruction open={open} setOpen={setOpen} />

      <Error
        open={errorOpen}
        setOpen={setErrorOpen}
        ErrorMessage={error}
        CloseButton={false}
      />
    </Fragment>
  );
}
