import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { BiSolidCheckCircle } from "react-icons/bi";
import { Container, Typography, Grid, Button, Box, Paper } from "@mui/material";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const AadharverificaionSuccess = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const qrcodeurl = queryParams.get("qrcodeurl");

  return (
    <OuterBox>
      <OuterPaper>
        {" "}
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            border: "#ffffff",
            height: "30vh",
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            spacing={3}
          >
            <BiSolidCheckCircle
              style={{
                fontSize: "80px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "rgb(41 128 31)",
                marginBottom: "30px",
              }}
            />
            <Typography
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "rgb(41 128 31)",
              }}
            >
              Verification has been completed successfully.
            </Typography>
            {qrcodeurl && (
              <Button
                variant="outlined"
                sx={{
                  marginTop: "15px",
                  "&:hover": { background: "rgb(41 128 31)", color: "white" },
                }}
                href={qrcodeurl}
              >
                Download QR Code
              </Button>
            )}
          </Grid>
        </Container>
      </OuterPaper>
    </OuterBox>
  );
};

export default AadharverificaionSuccess;
