import { Formik } from "formik";
import { QrReader } from "react-qr-reader";
import { Fragment, useState } from "react";

import { useAxios } from "../../hooks";
import OuterBox from "./../../components/OuterBox";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

const QRCode = ({ setAddBoardingPass }) => {
  const axios = useAxios();
  const location = useLocation();

  const [formData, setFormData] = useState({ qrData: "" });

  return (
    <OuterBox>
      <Formik
        initialValues={formData}
        onSubmit={(formData, { setSubmitting }) => {
          //   setSubmitting(true);
          console.log(formData, "ewefwef");

          axios({
            url: "event/visitor/save-boarding-pass",
            method: "POST",
            data: { data: formData.qrData, visitor_id: location.state },
            customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
          }).then((response) => {
            if (response.data) {
              setAddBoardingPass(false);
            }
          });
        }}
      >
        {(formikProps) => {
          return (
            <Fragment>
              <Typography textAlign={"center"} fontWeight={900}>
                Scan QR Code{" "}
              </Typography>
              <QrReader
                style={{ width: "100%" }}
                onResult={(result, error) => {
                  if (!!result) {
                    formikProps.setFieldValue("qrData", result?.text);
                    formikProps.handleSubmit();
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                constraints={{
                  facingMode: "environment",
                }}
                scanDelay={500}
              />
            </Fragment>
          );
        }}
      </Formik>
    </OuterBox>
  );
};

export default QRCode;
