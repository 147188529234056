import React from "react";
import { Box, Paper } from "@mui/material";

const OuterBox = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "75vh",
      width: ["100%", "100%", "100%", "800px"],
      margin: "0 auto",
      padding: "0px 17px",
      boxSizing: "border-box",
      background: "#FFFFFF",
    }}
  >
    <Paper
      elevation={3}
      sx={{
        width: "100%",
        padding: "20px",
        // boxShadow: "0px 0px 2.5px 1px rgba(90, 90, 90, 0.51)",
        borderRadius: "5px",
        minHeight: "20vh",
        background: "rgba(255, 255, 255, 0.5)",
        alignContent: "center",
        borderRadius: "15px",
      }}
    >
      {children}
    </Paper>
  </Box>
);

export default OuterBox;
