export const eventBokingObject = {
  name0: "",
  mobile0: "",
  email0: "",
  event: "",
  eventAmount: 0,
  subEvent0: "",
  subEventAmount0: 0,
  state0: "",
  district0: "",
  organization: "",
  gender0: "",
  designation: "",
  memberCount: 0,
  paymentStatue: "",
};

export function objectFromLocationData(data, eventObject, subEventOptions) {
  let value = {};

  if (Object.keys(data).includes("userData")) {
    data?.userData.map((item, index) => {
      value[`name${index}`] =
        item?.visitor_info?.first_name || item?.first_name;

      value[`mobile${index}`] =
        item?.phone_number ||
        item?.visitor_info?.phone_number ||
        sessionStorage.getItem("mobileNumber");

      value[`email${index}`] =
        item?.email_id ||
        item?.visitor_info?.email_id ||
        sessionStorage?.getItem("email");

      value.event = item?.event_id;

      value.eventAmount = eventObject?.ticket_cost;

      value[`state${index}`] = item?.state_id;

      value[`district${index}`] = item?.district_id;

      value.organization = item?.organization_name || "";

      value.designation = item?.designation || "";

      value[`subEvent${index}`] = item?.badge_id;

      value[`subEventAmount${index}`] = item?.badge_id_cost;

      value[`gender${index}`] = item?.gender || item?.visitor_info?.gender;

      value.memberCount = index;

      value.paymentStatue = item?.payment_status;
    });
  } else {
    value[`name0`] = data?.visitor_info?.first_name || data?.first_name;
    value[`mobile0`] =
      data?.phone_number ||
      data?.visitor_info?.phone_number ||
      sessionStorage.getItem("mobileNumber");
    value[`email0`] =
      data?.email_id ||
      data?.visitor_info?.email_id ||
      sessionStorage?.getItem("email");
    value.event = data?.event_id;
    value.eventAmount = eventObject?.ticket_cost;
    value[`state0`] = data?.state_id;
    value[`district0`] = data?.district_id;
    value.organization = data?.organization_name || "";
    value.designation = data?.designation || "";
    value[`subEvent0`] = data?.badge_id;
    value[`subEventAmount0`] = data?.badge_id_cost;
    value[`gender0`] = data?.gender || data?.visitor_info?.gender;

    value.paymentStatue = data?.payment_status;

    value.memberCount = 0;
  }

  return value;
}

export function objectFromData(data, id, badge) {
  let formData = [];
  let badgeTotal = 0;
  let count = 0;
  for (let i = 0; i <= data.memberCount; i++) {
    formData[i] = {
      ...(i === 0 && { visitor_id: id }),
      state_id: data[`state${i}`],
      district_id: data[`district${i}`],
      name: data[`name${i}`],
      gender: data[`gender${i}`],
      phone_number: "91" + data[`mobile${i}`],
      email_id: data[`email${i}`],
      ...(i === 0 && { designation: data.designation || "" }),
      ...(i === 0 && { organization_name: data.organization || "" }),
      badge_id: data[`subEvent${i}`],
      booking_date: Date.now() / 1000,
      sub_event_amount: data[`subEventAmount${i}`],
      sub_event: data[`subEvent${i}`],
    };

    badgeTotal += data[`subEventAmount${i}`];

    if (
      Boolean(
        data[`name${i}`] &&
          data[`gender${i}`] &&
          data[`mobile${i}`] &&
          data[`email${i}`]
      )
    ) {
      count++;
    }
  }

  return {
    event_id: data.event,
    total_price: data.eventAmount * count + (badgeTotal ? badgeTotal : 0),
    reference_visitor_id: id,
    visitors: formData,
  };
}
