import React from "react";

const ButtonContainer = ({ children }) => (
  <div style={styles.buttonContainer}>{children}</div>
);

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: "10px", // Adjust the gap value as needed
    marginTop: "20px", // Add margin at the top for spacing
  },
};

export default ButtonContainer;
