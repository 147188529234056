import { Box } from "@mui/material";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import data from "./ShopData";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h5 {
    font-size: 18px;
    margin: 10px 0;
  }

  .info {
    text-align: center;
  }

  .icons {
    margin-top: 10px;
  }
`;

const ShopView = () => {
  const location = useLocation();

  const [item, setItem] = useState({});

  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");

  useEffect(() => {
    let shopDetail = data.filter((item, index) => {
      return index == id;
    });
    setItem(shopDetail);
  }, [id]);

  return (
    <OuterBox>
      <OuterPaper>
        <ListContainer>
          <ListItem>
            <a>
              <figure className="shops-picture">
                <div>
                  <h5>Category : {item[0]?.category}</h5>
                </div>
              </figure>
              <figure className="shops-picture">
                <picture>
                  <img
                    src={
                      "https://www.stuttgart-airport.com/media/185592/5_vor_flug_5060_lowres.jpg?anchor=center&mode=crop&width=600&height=400&rnd=131469262140000000"
                    }
                    alt={item[0]?.name}
                  />
                </picture>
              </figure>
              <figure className="shops-picture">
                <div>
                  <h5>{item[0]?.name}</h5>
                  <p className="open">
                    <b>
                      Open from {item[0]?.hour}
                      <br />
                    </b>
                  </p>
                  <div className="icons">
                    <i
                      className="icon-close"
                      role="img"
                      aria-label="closed"
                    ></i>
                  </div>
                  <div className="info">
                    <div className="place">{item[0]?.location?.place}</div>
                    <div className="secure-area">
                      {item[0]?.location?.secure_area}
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="shops-picture">
                <table className="hours-table">
                  <tbody>
                    <tr class="">
                      <td>Monday</td>
                      <td colspan="2">{item[0]?.hours?.Monday}</td>
                    </tr>
                    <tr class="today">
                      <td>Tuesday</td>
                      <td colspan="2">{item[0]?.hours?.Tuesday}</td>
                    </tr>
                    <tr class="">
                      <td>Wednesday</td>
                      <td colspan="3">{item[0]?.hours?.Wednesday} </td>
                    </tr>
                    <tr class="">
                      <td>Thursday</td>
                      <td colspan="2">{item[0]?.hours?.Thursday}</td>
                    </tr>
                    <tr class="">
                      <td>Friday</td>
                      <td colspan="2">{item[0]?.hours?.Friday}</td>
                    </tr>
                    <tr class="">
                      <td>Saturday</td>
                      <td colspan="2">{item[0]?.hours?.Saturday}</td>
                    </tr>
                    <tr class="">
                      <td>Sunday</td>
                      <td colspan="2">{item[0]?.hours?.Sunday}</td>
                    </tr>
                  </tbody>
                </table>
              </figure>
            </a>
          </ListItem>
        </ListContainer>
      </OuterPaper>
    </OuterBox>
  );
};

export default ShopView;
