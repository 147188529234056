import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { useOutlet } from "react-router-dom";

const Wrapper = styled(Box)`
  flex-grow: 1;
`;

export default function MainContent() {
  const element = useOutlet();
  return <Wrapper>{element}</Wrapper>;
}
