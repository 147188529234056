import React from "react";
import styled from "styled-components";

const BoardingPassContainer = styled.div`
  position: relative;
  left: 0.7rem;
  width: 91%;
  height: 230px;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px 1px;
  overflow: hidden;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Header = styled.header`
  background: linear-gradient(to bottom, #264b76 0%, #002c5f 100%);
  padding: 12px 16px;
  height: 55px;
  border-bottom: 4px solid #6dbe47;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoDiv = styled.div`
  color: white;
  width: 230px;
  height: 31px;
`;

const FlightInfoDiv = styled.div`
  /* Styles can be applied dynamically here */
`;

const SectionCities = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const PlaneIcon = styled.div`
  display: flex;
  padding: 10px;
  img {
    width: 30px;
  }
`;

const SectionInfos = styled.section`
  display: flex;
  border-top: 1px solid #99d298;
`;

const FlightInfoBox = styled.div`
  background: #e2efe2;
  border-right: 1px solid #99d298;
  width: 45%;
`;

const FlightTimesBox = styled.div`
  width: 50%;
`;

const SectionStrap = styled.section`
  clear: both;
  position: relative;
  border-top: 1px solid #99d298;
`;

const QRCodeImg = styled.img`
  position: absolute;
  top: 3px;
  right: 20px;
  width: 80px;
  height: 80px;
`;

const FlightInfo = ({ name, code, cssDiv, cssSmall, cssStrong }) => {
  return (
    <FlightInfoDiv style={cssDiv}>
      <small style={cssSmall}>{name}</small>
      <strong style={cssStrong}>{code}</strong>
    </FlightInfoDiv>
  );
};

const RenderBoardingPass = ({ detail }) => {
  console.log(detail, "detail");
  return (
    <BoardingPassContainer>
      <Header>
        <FlightInfoDiv style={{ color: "#fff", textAlign: "right" }}>
          <small
            style={{
              fontSize: "10px",
              marginBottom: "2px",
              opacity: "0.8",
              fontWeight: "900",
            }}
          >
            {"flight PNR"}
          </small>
          <strong style={{ fontSize: "18px", marginLeft: "10px" }}>
            {detail.pnr}
          </strong>
        </FlightInfoDiv>
      </Header>

      <SectionCities>
        <FlightInfo
          name="Departure City"
          code={detail?.origin}
          cssDiv={{ padding: "15px 18px", float: "left" }}
          cssSmall={{ marginBottom: "0px", marginLeft: "3px" }}
          cssStrong={{ fontSize: "35px", fontWeight: "300", lineHeight: "1" }}
        />
        <PlaneIcon>
          <img
            src="https://cdn.onlinewebfonts.com/svg/img_537856.svg"
            alt="Plane"
          />
        </PlaneIcon>
        <FlightInfo
          name="Arrival City"
          code={detail?.destination}
          cssDiv={{ padding: "15px 18px", float: "right" }}
          cssSmall={{ marginBottom: "0px", marginLeft: "3px" }}
          cssStrong={{ fontSize: "35px", fontWeight: "300", lineHeight: "1" }}
        />
      </SectionCities>

      <SectionInfos></SectionInfos>

      <SectionStrap>
        <FlightInfo
          name="passenger"
          code={detail.name}
          cssDiv={{ margin: "10% 2%" }}
          cssSmall={{ fontSize: "10px" }}
          cssStrong={{ fontSize: "13px", marginLeft: "10px" }}
        />

        <QRCodeImg
          src="https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1729055510334_QR_code_for_mobile_English_Wikipedia.svg.png"
          alt="QR Code"
        />
      </SectionStrap>
    </BoardingPassContainer>
  );
};

export default RenderBoardingPass;
